import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores/BusinessClient";
import { useTariffSelection } from "../../../../../hooks/PencilSelling/useTariffSelection";
import { ILandlineSettings } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { PortfolioKeys } from "../../../../../core/entities/Product/IProduct";
import { businessCaseOptions } from "../../../../../core/entities/Product/Tariff/ITariff";
import TariffMainWrapper from "../../../../../components/new-design/Tariff/TariffMainWrapper";
import LandlineTariffConfiguration from "../../../../../components/new-design/Tariff/TariffConfiguration/LandlineTariffConfiguration";
import TariffSelection from "../../../../../components/new-design/Tariff/TariffSelection";
import TariffSelectionBenefit from "../../../../../components/new-design/Tariff/TariffSelection/TariffSelectionBenefit";
import { CustomerType } from "../../../../../core/entities/PencilSelling/Customer/ICustomer";
import { useLandlineTariffSelection } from "../../../../../hooks/PencilSelling/useLandlineTariffSelection";
import { useTariffSelectionData } from "../../../../../hooks/PencilSelling/useTariffSelectionData";
import TariffSelectionHeader from "../../../../../components/new-design/Tariff/TariffSelection/TariffSelectionHeader";
import AddonsResetWarningModal from "../../../../../components/new-design/Tariff/AddonsResetWarningModal";
import { getFilteredTariff } from "../../../../../helpers/TariffSelection";
import { LandlinePromotionConditions } from "../../../../../core/repositories/ProductsRepository/DefinitionKeys";

const TRACKING_CONTEXT = {
  main: "wunschtarif-geschaefts",
  portfolio: "festnetz",
};

const LandlineTariffSelection = () => {
  const {
    offerStore,
    configurationsStore,
    productsStore,
    productsRepositoryStore,
  } = useStores();

  const { tariffs } = productsStore;

  const {
    settings: landlineSettings,
    isBenefitActive,
    tariffInCart,
    activeTariff: landlineTariff,
    setActiveTariff: setLandlineTariff,
    selectedPromotions: selectedLandlinePromotions,
    addToCart,
    addPromotionsHandler,
    warningModalCallback,
    warningModalIsOpen,
    setWarningModalIsOpen,
    removeTariffAddonsDialogHandler,
    customPromotions,
  } = useTariffSelection<ILandlineSettings>(
    PortfolioKeys.LANDLINE,
    tariffs[PortfolioKeys.LANDLINE],
    configurationsStore,
    productsStore,
    offerStore.getBaseOffer()
  );
  const { businessCase } = landlineSettings;

  const {
    tariffWorlds,
    levels,
    activeLevelExists,
    filteredTariffs,
    activeLevelIndex,
    setActiveLevelIndex,
    businessCaseName,
    activeTariffWorld,
  } = useTariffSelectionData({
    businessCase,
    isLandline: true,
    settings: landlineSettings,
    tariffs: tariffs[PortfolioKeys.LANDLINE],
    businessCaseData: businessCaseOptions,
  });

  const {
    toggleLandlineBenefit,
    handleLandlineTariffChange,
    handleLandlineSettingsChange,
    getLandlineTariffFeature,
  } = useLandlineTariffSelection({
    landlineSettings,
    businessCase,
    configurationsStore,
    landlineTariff,
    setLandlineTariff,
    isBenefitActive,
    addToCart,
    selectedLandlinePromotions,
    tariffs: tariffs[PortfolioKeys.LANDLINE],
  });
  // TODO Temporary hidden for MVP
  // const getTariffDescription = (
  //   tariff: ITariff,
  //   showTelefonflat: boolean
  // ): string => {
  //   if (tariff.key === "magenta_home_xs") {
  //     return "";
  //   }
  //   if (Number(Bandwidths[tariff.size]) > 3) {
  //     return "Telefonie-Flat";
  //   }
  //   if (showTelefonflat && isBenefitActive) {
  //     return "Telefonie-Flat";
  //   }
  //
  //   return "Festnetzflat";
  // };

  const applyLandlineFilter = (filterSettings: Partial<ILandlineSettings>) => {
    const updatedSettings = {
      ...landlineSettings,
      ...filterSettings,
      businessCase,
    };
    if (!landlineTariff) {
      handleLandlineSettingsChange(null, null, updatedSettings);
      return;
    }

    const tariffIsExist = getFilteredTariff(
      tariffs[PortfolioKeys.LANDLINE],
      landlineTariff,
      updatedSettings,
      true
    );

    if (!tariffIsExist) {
      removeTariffAddonsDialogHandler(() => {
        handleLandlineSettingsChange(null, null, updatedSettings);
      });
      return;
    }
    handleLandlineSettingsChange(null, null, updatedSettings);
  };

  const { customerType } = offerStore.getBaseOffer().customer.get();

  const varioIsDisplayed = customerType === CustomerType.BUSINESS;

  const withMultipleOnceMonthlyPromotions =
    customerType === CustomerType.EMPLOYEE;

  return (
    <TariffMainWrapper>
      <LandlineTariffConfiguration
        withMultipleOnceMonthlyPromotions={withMultipleOnceMonthlyPromotions}
        withCustomPromotions
        customPromotions={customPromotions[PortfolioKeys.LANDLINE]}
        offerStore={offerStore.getBaseOffer()}
        productsRepositoryStore={productsRepositoryStore}
        activeTariffInCart={tariffInCart}
        activeTariff={landlineTariff}
        trackingContext={TRACKING_CONTEXT}
        tariffWorlds={tariffWorlds}
        levels={levels}
        onSettingsChange={(key, value) => {
          removeTariffAddonsDialogHandler(() => {
            handleLandlineSettingsChange(key, value);
          });
        }}
        setActiveLevelIndex={setActiveLevelIndex}
        activeLevelIndex={activeLevelIndex}
        activeLevelExists={activeLevelExists}
        settings={landlineSettings}
        selectedPromotions={selectedLandlinePromotions}
        addPromotionsHandler={addPromotionsHandler}
        businessCase={businessCase}
        businessCaseName={
          // Temporary solution until GUI will be updated.
          customerType === CustomerType.BUSINESS &&
          businessCaseName === LandlinePromotionConditions.broadband_prospect
            ? "Festnetz-Bestandskunde"
            : businessCaseName
        }
        configurationsStore={configurationsStore}
        applyLandlineFilter={applyLandlineFilter}
      />
      <TariffSelection
        tariffs={filteredTariffs}
        activeTariff={landlineTariff}
        isBenefitActive={isBenefitActive}
        settings={landlineSettings}
        handleTariffChange={(tariff, activeTariff) => {
          removeTariffAddonsDialogHandler(() =>
            handleLandlineTariffChange(tariff, activeTariff)
          );
        }}
        getTariffFeature={getLandlineTariffFeature}
        trackingContext={TRACKING_CONTEXT}
      >
        <TariffSelectionHeader
          checkboxLabelText="Vario-Tarife anzeigen"
          checkboxIsDisplayed={varioIsDisplayed}
          settings={landlineSettings}
          settingKey="isVario"
          isChecked={landlineSettings.isVario}
          onChange={() => {
            removeTariffAddonsDialogHandler(() =>
              handleLandlineSettingsChange("isVario", !landlineSettings.isVario)
            );
          }}
          activeTariffWorld={activeTariffWorld}
        />
        <div className="tariff-entries-bonus-wrapper">
          <TariffSelectionBenefit
            withLoyaltyBonus={false}
            isActive={isBenefitActive}
            onClick={() => toggleLandlineBenefit(landlineTariff)}
          />
        </div>
      </TariffSelection>
      <AddonsResetWarningModal
        trackingContext={TRACKING_CONTEXT}
        resetOptionsModalCallback={warningModalCallback}
        setResetOptionsModalIsOpen={setWarningModalIsOpen}
        resetOptionsModalIsOpen={warningModalIsOpen}
      />
    </TariffMainWrapper>
  );
};

export default observer(LandlineTariffSelection);
