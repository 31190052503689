import faker from "faker";
import type { ICart } from "core/entities/PencilSelling/Cart/ICart";
import { ICartItem, ICartItemData, PaymentTypes } from "core/entities/PencilSelling/CartItem/ICartItem";
import type { ICustomer, ICustomerData, ICustomerEntity } from "core/entities/PencilSelling/Customer/ICustomer";
import { CustomerType, DomainType, IUserGroups } from "core/entities/PencilSelling/Customer/ICustomer";
import { IPrice } from "core/entities/Product/IPrice";
import { ILandlineSettings, IMobileSettings, INotes, IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import { Group, PortfolioKeys } from "core/entities/Product/IProduct";
import type { IProductsEntity } from "core/entities/PencilSelling/Products/IProductsEntity";
import type { ISalesPerson } from "core/entities/PencilSelling/SalesPerson/ISalesPerson";
import type { IProductsRepository } from "core/repositories/ProductsRepository/IProductsRepository";
import type { IPromotion } from "core/entities/PencilSelling/IPromotion";
import { Bandwidths, BusinessCasesLandline, BusinessCasesMobile } from "core/entities/Product/Tariff/ITariff";
import { ExpansionArea } from "core/entities/Applications";
import { IBenefit } from "core/entities/Product/IBenefit";
import { CustomPromotionTypes, ICustomPromotion } from "core/entities/PencilSelling/ICustomPromotion";
import { IServicesEntity } from "../entities/PencilSelling/Services/IServicesEntity";
import { IHomeConfigurator } from "../entities/PencilSelling/HomeConfigurator/IHomeConfigurator";
import { PromotionPriceType } from "../entities/Product/IDiscount";
import { IPromotionSectionsKeys } from "../entities/PencilSelling/IPromotionSectionsKeys";
import {
  CUSTOMER_DATA_DEFAULT,
  GROSS_PRICE_PREFIX,
} from "../entities/PencilSelling/Customer/const";

export const priceMock = (): IPrice => ({
  monthly: faker.datatype.number(200),
  once: faker.datatype.number(200),
  provision: faker.datatype.number(200),
  originalPrice: null,
});

export const customPromotionMock = (): ICustomPromotion => ({
  isEditable: true,
  key: faker.random.word(),
  portfolio: PortfolioKeys.MOBILE,
  name: faker.random.word(),
  description: faker.random.word(),
  type: CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE,
  discount: {
    value: faker.datatype.number(),
    interval: faker.datatype.number(),
    kind: PromotionPriceType.CUSTOM_PROMOTION,
  },
});

export const cartMock = (): ICart => ({
  items: [],
  find: jest.fn(),
  setQuantity: jest.fn(),
  getLandlineOptions: jest.fn(),
  getMobileOptions: jest.fn(),
  getLandlineTariff: jest.fn(),
  getMobileTariff: jest.fn(),
  getCards: jest.fn(),
  getDevices: jest.fn(),
  add: jest.fn(),
  delete: jest.fn(),
  replace: jest.fn(),
  deleteLandlineTariff: jest.fn(),
  deleteMobileTariff: jest.fn(),
  deleteMultiSimBySize: jest.fn(),
  reset: jest.fn(),
  resetMobile: jest.fn(),
  resetLandline: jest.fn(),
  resetCards: jest.fn(),
  updateDynamicCardDescriptions: jest.fn(),
  filterActiveOptionsByTariffKey: jest.fn(),
  resetOptions: jest.fn(),
  setLandlineTariffExtension: jest.fn(),
  getCurrentLandlineTariffExtension: jest.fn(),
});

interface ICartItemMockProps {
  portfolio?: PortfolioKeys;
  group?: Group;
  key?: string;
}

export const cartItemDataMock = (
  options?: ICartItemMockProps
): ICartItemData => ({
  portfolio:
    options?.portfolio ||
    faker.random.arrayElement([PortfolioKeys.LANDLINE, PortfolioKeys.MOBILE]),
  paymentType: PaymentTypes.MONTHLY,
  name: faker.commerce.productName(),
  price: priceMock(),
  key: options?.key || faker.datatype.uuid(),
  quantity: 1,
  isFungible: false,
  group:
    options?.group ||
    faker.random.arrayElement([
      Group.addon,
      Group.tariff,
      Group.card,
      Group.device,
    ]),
  tariffWorld: {
    key: "magenta_mobile_prepaid",
    name: "MagentaMobil Prepaid",
    valency: faker.datatype.number(),
    links: {
      young: null,
      default: null,
      link_title: null,
    },
    limit: null,
  },
});

export const cartItemMock = ({
  portfolio,
  group,
  key,
}: ICartItemMockProps | undefined): ICartItem => ({
  ...cartItemDataMock({ group, key, portfolio }),
  setQuantity: jest.fn(),
  getPrice: jest.fn(),
  export: jest.fn(),
});

export const salesPersonMock = (): ISalesPerson => ({
  name: faker.random.word(),
  company: {
    street: faker.address.streetName(),
    postcode: faker.address.zipCode(),
    city: faker.address.city(),
    houseNumber: faker.address.streetSuffix(),
  },
});

export const landlineSettingsMock = (): ILandlineSettings => ({
  portfolio: PortfolioKeys.LANDLINE,
  businessCase: BusinessCasesLandline.KOMPLETTWECHSLER,
  fiber: true,
  bandwidth: Bandwidths.M,
  isYoung: true,
  isVario: null,
  tariffWorld: "",
  level: "",
  expansionArea: ExpansionArea.telekom,
});

export const mobileSettingsMock = (): IMobileSettings => ({
  portfolio: PortfolioKeys.MOBILE,
  businessCase: BusinessCasesMobile.NEUVERTRAG,
  isYoung: true,
  isVario: null,
  tariffWorld: "",
  level: "",
  hasProvisionFee: true,
  loyaltyBonusValue: 0,
});

export const customerEntityMock = (): ICustomerEntity => ({
  get: jest.fn(),
  set: jest.fn(),
  reset: jest.fn(),
});

export const customerMock = (): ICustomer => ({
  notice: faker.random.words(5),
  consultingTime: {
    nextAppointmentDate: new Date(),
    nextAppointmentTime: "12:50",
  },
  customerType: CustomerType.EMPLOYEE,
  customerPricePrefix: GROSS_PRICE_PREFIX,
  domainType: DomainType.BUSINESS,
  userGroup: IUserGroups.DEFAULT,
  customerData: CUSTOMER_DATA_DEFAULT as ICustomerData,
});

export const mockNotes = (): INotes => ({
  landline: faker.random.words(10),
  mobile: faker.random.words(10),
});

export const mockPromotion = (): IPromotion => ({
  name: faker.random.word(),
  key: faker.datatype.uuid(),
  conditions: [BusinessCasesLandline.BESTANDSKUNDE_DP],
  concerned: faker.random.word(),
  description: faker.random.word(),
  discount: {
    description: faker.random.word(),
    value: 10,
    interval: null,
    from: null,
    to: null,
    kind: PromotionPriceType.CUSTOM_PROMOTION,
  },
  summaryDescription: {
    summary: "",
    text: "",
  },
});

export const mockCustomPromotion = (
  portfolioKey: IPromotionSectionsKeys
): ICustomPromotion => ({
  isEditable: true,
  name: faker.random.word(),
  key: faker.datatype.uuid(),
  description: faker.random.word(),
  type: CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE,
  portfolio: portfolioKey,
  discount: {
    description: faker.random.word(),
    value: 10,
    interval: null,
    kind: PromotionPriceType.CUSTOM_PROMOTION,
  },
});

export const mockBenefit = (): IBenefit => ({
  name: faker.random.word(),
  key: faker.datatype.uuid(),
  options: [],
});

export const offerMock = (): IOffer => ({
  cart: cartMock(),
  getLandlineSettings: jest.fn(),
  setLandlineSettings: jest.fn(),
  setMobileSettings: jest.fn(),
  getMobileSettings: jest.fn(),
  setCardSettings: jest.fn(),
  getCardSettings: jest.fn(),
  setCustomPromotions: jest.fn(),
  getCustomPromotions: jest.fn(),
  removeCustomPromotion: jest.fn(),
  replaceCustomPromotion: jest.fn(),
  getTariffBenefits: jest.fn(),
  setTariffBenefits: jest.fn(),
  getNotes: jest.fn(),
  setNotes: jest.fn(),
  getValidUntil: jest.fn(),
  setValidUntil: jest.fn(),
  showLoyaltyBonus: jest.fn(),
  reset: jest.fn(),
  export: jest.fn(),
  salesPerson: salesPersonMock(),
  customer: customerEntityMock(),
  validUntil: { date: new Date() },
  getProfileDetailsInPdfIsDisplayed: jest.fn(),
  setProfileDetailsInPdfIsDisplayed: jest.fn(),
});

export const mockProductsRepository = (): jest.Mocked<IProductsRepository> => ({
  getProducts: jest.fn(),
  submitOrder: jest.fn(),
  submitPdfQrCode: jest.fn(),
  getOfferPdfLink: jest.fn(),
  submitCalculatorPdfQrCode: jest.fn(),
  getSreamingAnalysis: jest.fn(),
  getCalculatorProducts: jest.fn(),
  getCalculatorPDF: jest.fn(),
  postCustomPromotion: jest.fn(),
  editCustomPromotion: jest.fn(),
  deleteCustomPromotion: jest.fn(),
  fetchCustomPromotion: jest.fn(),
  setCustomPromotion: jest.fn(),
  fetchCustomAddons: jest.fn(),
  createCustomAddon: jest.fn(),
  deleteCustomAddon: jest.fn(),
  logout: jest.fn(),
});

export const mockProductsEntity = (): jest.Mocked<IProductsEntity> => ({
  getLandlineTariffs: jest.fn(),
  getMobileTariffs: jest.fn(),
  getCards: jest.fn(),
  getDevices: jest.fn(),
  getLandlineOptions: jest.fn(),
  getMobileOptions: jest.fn(),
  setup: jest.fn(),
});

export const mockHomeConfiguratorEntity =
  (): jest.Mocked<IHomeConfigurator> => ({
    setSquareMetersKey: jest.fn(),
    setSquareMetersValue: jest.fn(),
    setActiveFloorVariant: jest.fn(),
    setRouterIndex: jest.fn(),
    setFloorsVariants: jest.fn(),
    reset: jest.fn(),
  });

export const mockFetch = (
  data: string,
  fileType?: string
): jest.Mock<Promise<any>> =>
  jest.fn(() =>
    Promise.resolve({
      ok: true,
      json: () => data,
      blob: () =>
        new Blob([data], {
          type: fileType,
        }),
    })
  );

export const mockServicesEntity = (): jest.Mocked<IServicesEntity> => ({
  getStreamingServices: jest.fn(),
  getStreamingVariants: jest.fn(),
  setup: jest.fn(),
  setStreamingServiceActive: jest.fn(),
  getStreamingServicesPrice: jest.fn(),
});
